import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` component behaves the same as the `}<inlineCode parentName="p">{`Box`}</inlineCode>{` component except that it has `}<inlineCode parentName="p">{`display: flex`}</inlineCode>{` set by default.`}</p>
    <p><em parentName="p">{`Previously, a `}<inlineCode parentName="em">{`Flex.Item`}</inlineCode>{` component was used for flex item specific properties; `}<inlineCode parentName="em">{`Box`}</inlineCode>{` now contains all those properties and should be used in its place.`}</em></p>
    <h2>{`Default example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<BorderBox width={300} height={300} borderRadius={0}>
  <Flex flexWrap="nowrap">
    <Box p={3} bg="blue.5">
      Item 1
    </Box>
    <Box p={3} bg="green.5">
      Item 2
    </Box>
    <Box p={3} bg="yellow.5">
      Item 3
    </Box>
  </Flex>
</BorderBox>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Flex components get `}<inlineCode parentName="p">{`FLEX`}</inlineCode>{`, `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, and `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{` system props.`}</p>
    <p>{`Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p><inlineCode parentName="p">{`Flex`}</inlineCode>{` does not get any additional props other than the system props mentioned above.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      